import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { sync } from "vuex-router-sync";
import vuetify from "@/plugins/vuetify";
import store from "@/store/store";
//TODO Import Echarts locally
import ECharts from "vue-echarts";
import moment from "moment";
import i18n from "@/plugins/i18n";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import FlagIcon from "vue-flag-icon";


Vue.prototype.moment = moment;
Vue.use(FlagIcon);
Vue.use(ECharts);
Vue.config.productionTip = false;

sync(store, router);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
