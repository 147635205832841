<template>
  <nav>
    <v-toolbar dense flat height="36vh" class="grey lighten-4">
      <v-app-bar-nav-icon
        v-if="$store.state.isUserLoggedIn"
        @click="drawer = !drawer"
      >
      </v-app-bar-nav-icon>

      <v-btn plain @click="navigateTo({ name: 'root' })">
        <v-icon>home</v-icon>
        {{ $t("navBar.home") }}
      </v-btn>

      <v-col>
        <v-row class="justify-center" align="center">
          <!-- <v-img
            v-if="$store.state.isUserLoggedIn"
            style="max-width: 24px; max-height: 24px"
            class="mt-1"
            src="assets/heSymbolResized.png"
          ></v-img> -->
          <div>
            <span
              v-if="this.$vuetify.breakpoint.width > 900"
              style="font-size: 1.2vw"
              class="grey--text text-uppercase font-weight-bold font-italic"
              >Human Experience - Welfare Analysis
            </span>
          </div>
        </v-row>
      </v-col>


      <!-- LANGUAGE FLAGS -->

      <!-- <div>
        {{ $t("navBar.language") }}
        <button
          v-for="entry in languages"
          :key="entry.title"
          @click="changeLocale(entry.language)"
        >
          <flag :iso="entry.flag" v-bind:squared="false" /> {{ entry.title }}
        </button>
      </div> -->

      <v-btn
        plain
        v-if="!$store.state.isUserLoggedIn"
        @click="navigateTo({ name: 'login' })"
      >
        <v-icon>login</v-icon>
        {{ $t("navBar.login") }}
      </v-btn>

      <v-btn
        plain
        v-if="!$store.state.isUserLoggedIn"
        @click="navigateTo({ name: 'register' })"
      >
        <v-icon>app_registration</v-icon>
        {{ $t("navBar.signup") }}
      </v-btn>

      <v-btn plain v-if="$store.state.isUserLoggedIn" @click="handleLogout">
        <v-icon>logout</v-icon>
        {{ $t("navBar.logout") }}
      </v-btn>
    </v-toolbar>

    <!-- NAVIGATION DRAWER -->

    <v-navigation-drawer
      absolute
      temporary
      v-model="drawer"
      class="primary"
      dark
      light
    >
      <!-- AVATAR / USERNAME & PROFILE DROPDOWN -->

      <v-list v-if="$i18n.locale == 'en'">
        <v-list-item>
          <v-list-item-avatar size="80">
            <v-img src="assets/hesymbol.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="white--text text-h5">
              {{ showUsername }}
              <p></p>
              <p>{{ "(" + userRole + ")" }}</p>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mt-4"></v-divider>

        <!-- DROPDOWN -->
      </v-list>

      <v-list v-if="$i18n.locale == 'pt'">
        <v-list-item>
          <v-list-item-avatar size="80">
            <v-img src="assets/hesymbol.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="white--text text-h5">
              {{ showUsername }}
              <p></p>
              <p>{{ "(" + userRole + ")" }}</p>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mt-4"></v-divider>
      </v-list>

      <!-- limitedPortal Links -->

      <v-list
        v-if="
          (userServices.limitedPortal || userServices.advPortal) &&
          $i18n.locale == 'en'
        "
      >
        <v-list-item
          class="mt-2"
          v-for="link in limitedPortalLinks"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text ml-n2">
              {{ link.text }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        v-if="
          (userServices.limitedPortal || userServices.advPortal) &&
          $i18n.locale == 'pt'
        "
      >
        <v-list-item
          v-for="link in limitedPortalLinksPt"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text ml-n2">
              {{ link.text }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!--DATA DROPWDOWN -->

      <v-list
        v-if="
          (userServices.limitedPortal || userServices.advPortal) &&
          $i18n.locale == 'en'
        "
      >
        <v-list-group
          class="mx-auto mt-0"
          color="white"
          prepend-icon="assessment"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Data</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in dataLinks"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list
        v-if="
          (userServices.limitedPortal || userServices.advPortal) &&
          $i18n.locale == 'pt'
        "
      >
        <v-list-group
          class="mx-auto mt-0"
          color="white"
          prepend-icon="assessment"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Dados</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in dataLinksPt"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- advPortal Links w/ DROPDWON -->

      <v-list v-if="userServices.advPortal && $i18n.locale == 'en'">
        <v-list-group class="mx-auto mt-0" color="white" prepend-icon="build">
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Advanced Tools</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in advPortalLinks"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-if="userServices.advPortal && $i18n.locale == 'pt'">
        <v-list-group class="mx-auto mt-0" color="white" prepend-icon="build">
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Avançadas</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in advPortalLinksPt"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- CONFIGURATION LINKS - LIMITED PORTAL -->

      <v-list v-if="userServices.limitedPortal && $i18n.locale == 'en'">
        <v-list-group class="mx-auto mt-0" color="white" prepend-icon="tune">
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Configurations</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in configurationLinks"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-if="userServices.limitedPortal && $i18n.locale == 'pt'">
        <v-list-group class="mx-auto mt-0" color="white" prepend-icon="tune">
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Configurações</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in configurationLinksPt"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-if="userServices.advPortal && $i18n.locale == 'en'">
        <v-list-group class="mx-auto mt-0" color="white" prepend-icon="tune">
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Configurations</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in advConfigurationLinks"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list v-if="userServices.advPortal && $i18n.locale == 'pt'">
        <v-list-group class="mx-auto mt-0" color="white" prepend-icon="tune">
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Configurações</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in advConfigurationLinksPt"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- MASTER TOOLS with DROPDOWN-->

      <v-list v-if="userRole === 'master'">
        <v-list-group
          class="mx-auto mt-0"
          color="white"
          prepend-icon="construction"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text mr-n10"
              >Master Tools</v-list-item-title
            >
          </template>

          <v-list-item
            v-for="i in masterLinks"
            :key="i.text"
            router
            :to="i.route"
            link
          >
            <v-list-item-title
              v-text="i.text"
              class="white--text mr-n1"
            ></v-list-item-title>

            <v-list-item-icon>
              <v-icon class="white--text" v-text="i.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      userServices: {},
      languages: [
        { flag: "gb", language: "en", title: " " },
        { flag: "pt", language: "pt", title: "" },
      ],
      limitedPortalLinks: [
        { icon: "dashboard", text: "Dashboard", route: "/lobby" },
      ],
      limitedPortalLinksPt: [
        { icon: "dashboard", text: "Página Principal", route: "/lobby" },
      ],
      dataLinks: [
        { icon: "groups", text: "Group Information", route: "/profile" },
        { icon: "table_chart", text: "Sensor Data", route: "/dataView" },
      ],
      dataLinksPt: [
        { icon: "groups", text: "Informação do Grupo", route: "/profile" },
        { icon: "table_chart", text: "Dados Sensores", route: "/dataView" },
      ],
      configurationLinks: [
        { icon: "group", text: "Users", route: "/users" },
        { icon: "settings", text: "General Settings", route: "/appSettings" },
        {
          icon: "stay_primary_landscape",
          text: "Device Settings",
          route: "/deviceSettings",
        },
        // { icon: "edit", text: "Edit Personal Info", route: "/personalInfo" },
      ],
      advConfigurationLinks: [
        { icon: "group", text: "Users", route: "/users" },
        { icon: "settings", text: "General Settings", route: "/appSettings" },
        {
          icon: "stay_primary_landscape",
          text: "Device Settings",
          route: "/deviceSettings",
        },
        {
          icon: "stay_primary_landscape",
          text: "Blueprint Settings",
          route: "/blueprintDisplay",
        },
      ],
      configurationLinksPt: [
        { icon: "group", text: "Utilizadores", route: "/users" },
        { icon: "settings", text: "Definições Gerais", route: "/appSettings" },
        {
          icon: "stay_primary_landscape",
          text: "Definições Dispositivo",
          route: "/deviceSettings",
        },
      ],
      advConfigurationLinksPt: [
        { icon: "group", text: "Utilizadores", route: "/users" },
        { icon: "settings", text: "Definições Gerais", route: "/appSettings" },
        {
          icon: "stay_primary_landscape",
          text: "Definições Dispositivo",
          route: "/deviceSettings",
        },
        {
          icon: "stay_primary_landscape",
          text: "Definições de Planta",
          route: "/blueprintDisplay",
        },
      ],
      advPortalLinks: [
        { icon: "analytics", text: "Analytics", route: "/analytics" },
        { icon: "notifications", text: "Control", route: "/control" },
        { icon: "warning", text: "Event Log", route: "/eventLog" },
      ],
      advPortalLinksPt: [
        { icon: "analytics", text: "Análise Dados", route: "/analytics" },
        { icon: "notifications", text: "Centro Controlo", route: "/control" },
        { icon: "warning", text: "Registo Eventos", route: "/eventLog" },
      ],
      masterLinks: [
        {icon: "dashboard", text: "Master Dashboard", route: "/masterDashboard"},
        { icon: "group", text: "Groups & Users", route: "/appUsers" },
        {
          icon: "stay_primary_landscape",
          text: "App Devices",
          route: "/appDevices",
        },
        {
          icon: "miscellaneous_services",
          text: "App Services",
          route: "/appServices",
        },
        { icon: "warning", text: "App Log", route: "/appLog" },
      ],
      username: "",
      drawer: false,
    };
  },
  methods: {
    drawerAction() {
      this.drawer = !this.drawer;
      this.$store.dispatch("setDrawer", this.drawer);
    },
    navigateTo(route) {
      this.$router.push(route).catch(err => {err});
    },
    handleLogout() {
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$router.push({
        name: "root",
      });
    },
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
  mounted() {
    this.userServices = this.$store.getters.userServices;
  },
  updated() {
    this.username = this.$store.getters.username;
    this.userServices = this.$store.getters.userServices;
  },
  computed: {
    showUsername() {
      return this.username;
    },
    ...mapState(["userRole"]),
  },
};
</script>

<style scoped></style>
