import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store";

// USER PAGES
const HomePage = () => import("@/pages/userPages/HomePage");
const Register = () => import("@/pages/userPages/Register");
const AccountValidation = () => import("@/pages/userPages/AccountValidation");
const RegisterCompany = () => import("@/pages/userPages/RegisterCompany");
const Login = () => import("@/pages/userPages/Login");
const LobbyPage = () => import("@/pages/userPages/LobbyPage");
const PersonalInfo = () => import("@/pages/userPages/PersonalInfo");
const DeviceSetup = () => import("@/pages/userPages/DeviceSetup");
const Profile = () => import("@/pages/userPages/Profile");
const AppSettings = () => import("@/pages/userPages/AppSettings");
const DeviceSettings = () => import("@/pages/userPages/DeviceSettings");
const Control = () => import("@/pages/userPages/Control");
const Analytics = () => import("@/pages/userPages/Analytics");
const Users = () => import("@/pages/userPages/Users");
const DataView = () => import("@/pages/userPages/DataView");
const EventLog = () => import("@/pages/userPages/EventLog");
const BlueprintDisplay = () => import("@/pages/userPages/BlueprintDisplay");
const ForgotPassword = () => import("@/pages/userPages/ForgotPassword");
const RssiInfo = () => import("@/pages/userPages/RssiInfo");

// MASTER PAGES
const AppUsers = () => import("@/pages/masterPages/AppUsers");
const AppDevices = () => import("@/pages/masterPages/AppDevices");
const AppLog = () => import("@/pages/masterPages/AppLog");
const AppServices = () => import("@/pages/masterPages/AppServices");
const MasterDashboard = () => import("@/pages/masterPages/MasterDashboard");

Vue.use(Router);

const myRouter = new Router({
  routes: [
    {
      path: "/",
      name: "root",
      component: HomePage,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next("/lobby");
        } else {
          next();
        }
      },
      meta: {
        title: "Home",
      },
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        title: "Register",
      },
    },
    {
      path: "/accountValidation",
      name: "accountValidation",
      component: AccountValidation,
      meta: {
        title: "Account Validation",
      },
    },
    {
      path: "/registerCompany",
      name: "registerCompany",
      component: RegisterCompany,
    },
    {
      path: "/deviceSetup",
      name: "deviceSetup",
      component: DeviceSetup,
      meta: {
        title: "Device Setup",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "Login",
      },
    },
    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: ForgotPassword,
      meta: {
        title: "Forgot Password",
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Profile",
      },
    },
    // Personal Info component is not being used atm
    {
      path: "/personalInfo",
      name: "personalInfo",
      component: PersonalInfo,
      // Navigation Guard
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
    },
    {
      path: "/lobby",
      name: "lobby",
      component: LobbyPage,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Lobby",
      },
    },
    {
      path: "/dataView",
      name: "DataView",
      component: DataView,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Data",
      },
    },
    {
      path: "/users",
      name: "users",
      component: Users,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Users",
      },
    },
    {
      path: "/appSettings",
      name: "appSettings",
      component: AppSettings,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Settings",
      },
    },
    {
      path: "/deviceSettings",
      name: "deviceSettings",
      component: DeviceSettings,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Device Settings",
      },
    },
    {
      path: "/eventLog",
      name: "eventLog",
      component: EventLog,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Event Log",
      },
    },
    {
      path: "/control",
      name: "control",
      component: Control,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Control",
      },
    },
    {
      path: "/analytics",
      name: "analytics",
      component: Analytics,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Analytics",
      },
    },
    {
      path: "/appDevices",
      name: "AppDevices",
      component: AppDevices,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userRole === "master") {
          next();
        } else {
          next("/");
        }
      },
      meta: {
        title: "App Devices",
      },
    },
    {
      path: "/appUsers",
      name: "AppUsers",
      component: AppUsers,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userRole === "master") {
          next();
        } else {
          next("/");
        }
      },
      meta: {
        title: "App Users",
      },
    },
    {
      path: "/appServices",
      name: "AppServices",
      component: AppServices,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userRole === "master") {
          next();
        } else {
          next("/");
        }
      },
      meta: {
        title: "App Services",
      },
    },
    {
      path: "/appLog",
      name: "AppLog",
      component: AppLog,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userRole === "master") {
          next();
        } else {
          next("/");
        }
      },
      meta: {
        title: "App Log",
      },
    },
    {
      path: "/masterDashboard",
      name: "/MasterDashboard",
      component: MasterDashboard,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userRole === "master") {
          next();
        } else {
          next("/");
        }
      },
      meta: {
        title: "Master Dashboard",
      },
    },
    {
      path: "/blueprintDisplay",
      name: "blueprintDisplay",
      component: BlueprintDisplay,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userServices.advPortal) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Blueprint Settings",
      },
    },
    {
      path: "/rssiInformation",
      name: "rssiInfo",
      component: RssiInfo,
      beforeEnter: (to, from, next) => {
        if (store.state.isUserLoggedIn && store.state.userServices.advPortal) {
          next();
        } else {
          next("/login");
        }
      },
      meta: {
        title: "Rssi Information",
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

export default myRouter;
