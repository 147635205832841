<template>
  <!--"In order for your application to work properly, you must wrap it in a v-app component. 
This component is used for dynamically managing your content area and is the mounting point 
for many components."-->
  <v-app id="app" class="webApp">
    <v-main>
      <nav-bar />

      <div
        v-if="this.$vuetify.breakpoint.width <= 1264"
        class="smallResContent"
        :style="{ 'background-image': 'url()' }"
      >
        <router-view />
      </div>
      <div
        v-if="this.$vuetify.breakpoint.width > 1264"
        class="content"
        :style="{ 'background-image': 'url()' }"
      >
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  watch: {
    $route: {
      handler: (to) => {
        document.title = `HEWA - ${to.meta.title}`;
        const link = document.querySelector("[rel='icon']");
        link.setAttribute("href", to.meta.icon);
      },
      immediate: true,
    },
  },
  data() {
    return {
      image: "/assets/logoDashboardResized.png",
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 0px;
  background-color: #3e77b6;
}

.webApp {
  /* display: grid; */
  /* grid-template-columns: 1fr 5fr; */
  height: auto;
  width: 100vw;
}

.smallResContent {
  background-color: #fafafa;
  border-radius: 10px;
  margin: 8px auto;
  width: 95vw;
  min-height: 100vh;
}

.content {
  background-color: #fafafa;
  border-radius: 10px;
  margin: 8px auto;
  width: 80vw;
  min-height: 100vh;
}
</style>
