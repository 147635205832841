import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  // icons from material design
  icons: {
    iconfont: 'mdiSvg',
    theme: {
      primary: '#0000fb',
      success: '#00be00',
      info: '#ffb700',
      error: '#f10000',
    }
  }
}

export default new Vuetify(opts)