import Vue from "vue";
import VueI18n from "vue-i18n";
import ptlang from "@/translations/pt"
import enlang from "@/translations/en"

Vue.use(VueI18n);

const messages = {
  pt: ptlang,
  en: enlang,
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;
