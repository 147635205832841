import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
Vue.config.devtools = true;

const myStore = new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false,
    group: null,
    devices: null,
    userRole: null,
    userServices: null,
    extras: null,
    blueprints: null,
    selectedServer: null,
  },
  getters: {
    userInfo: (state) => {
      return state.user;
    },
    username: (state) => {
      return state.user?.username;
    },
    userId: (state) => {
      return state.user.id;
    },
    groupId: (state) => {
      return state.user.refGroup;
    },
    groupInfo: (state) => {
      return state.group;
    },
    devices: (state) => {
      return state.devices;
    },
    userServices: (state) => {
      return state.userServices;
    },
    extras: (state) => {
      return state.extras;
    },
    blueprints: (state) => {
      return state.blueprints;
    },
    selectedServer: (state) => {
      return state.selectedServer;
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      if (token) {
        state.isUserLoggedIn = true;
      } else {
        state.isUserLoggedIn = false;
      }
    },
    setUser(state, user) {
      state.user = user;
    },
    setGroup(state, group) {
      state.group = group;
    },
    setDevices(state, devices) {
      state.devices = devices;
    },
    setRole(state, role) {
      state.userRole = role;
    }, 
    setServices(state, services) {
      state.userServices = services;
    },
    setExtras(state, extras) {
      state.extras = extras;
    },
    setBlueprints(state, blueprints) {
      state.blueprints = blueprints;
    },
    setServer(state, selectedServer) {
      state.selectedServer = selectedServer;
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setGroup({ commit}, group) {
      commit("setGroup", group);
    },
    setDevices({ commit }, devices) {
      commit("setDevices", devices);
    },
    setRole({ commit }, role) {
      commit("setRole", role);
    },
    setServices({ commit }, services) {
      commit("setServices", services);
    },
    setExtras({ commit }, extras) {
      commit("setExtras", extras);
    },
    setBlueprints({ commit }, blueprints) {
      commit("setBlueprints", blueprints);
    },
    setServer( {commit }, selectedServer) {
      commit("setServer", selectedServer);
    }
  },
});

export default myStore;
